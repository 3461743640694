import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Head from 'next/head';
import { useRouter } from 'next/router';
import AgeGateModal from 'components/AgeGateModal';
import Footer from 'components/Footer';
import Header from 'components/Header';
import useRichLinkClicks from 'hooks/useRichLinkClicks';
import { setStoreSelectionByName } from 'stores/actions/store-selection';
import { showCartWarningModal } from 'stores/reducers/cart-warning-modal';
import {
  setCurrentModal,
  MODAL_SELECT_STORE,
  MODAL_SELECT_STORE_DELIVERY,
} from 'stores/reducers/store-selection-modal';
import formatStoreName from 'utils/format-store-name';
import isEmpty from 'utils/is-empty';

export default function Layout({ children, footer, header }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const interceptRichLinkClicks = useRichLinkClicks(router);
  const deliveryEnabled = useSelector((state) => state.featureFlags.delivery);
  const { storeSelection, cartInitialized, hasLineItems, modalOpen } =
    useSelector(({ cart, modalOpen, storeSelection }) => ({
      cartInitialized: cart.scope,
      hasLineItems: cart.lineItems.length > 0,
      modalOpen,
      storeSelection,
    }));

  const ageRestrictionAccepted = useSelector((store) => store.ageGate);
  const ageGateFF = useSelector((state) => state.featureFlags.age_gate);

  const {
    store: storeName,
    ordertype: pickupOrDelivery,
    storeSelector,
  } = router.query;

  const [storeChangeTriggered, setStoreChangeTriggered] = useState(false);
  const [ageGateEnabled, setAgeGateEnabled] = useState(false);

  useEffect(() => {
    if (
      (cartInitialized || (storeName && !storeSelection)) &&
      !storeChangeTriggered &&
      formatStoreName(storeName) !== formatStoreName(storeSelection)
    ) {
      const pickUpOrDeliveryLowerCase = deliveryEnabled
        ? pickupOrDelivery?.toLowerCase()
        : 'pickup';

      if (!isEmpty(storeName) && hasLineItems) {
        dispatch(
          showCartWarningModal({
            pendingStoreName: storeName,
            pickupOrDelivery: pickUpOrDeliveryLowerCase,
            type: 'store',
          })
        );
      } else {
        dispatch(
          setStoreSelectionByName(
            storeName || storeSelection,
            pickUpOrDeliveryLowerCase
          )
        );
      }
      setStoreChangeTriggered(true);
    }
  }, [
    dispatch,
    storeChangeTriggered,
    storeName,
    storeSelection,
    cartInitialized,
    hasLineItems,
    pickupOrDelivery,
  ]);

  useEffect(() => {
    if (storeName) {
      setStoreChangeTriggered(false);
    }
  }, [storeName]);

  useEffect(() => {
    if (!isEmpty(storeSelector) && ageRestrictionAccepted) {
      const shoppingMode =
        storeSelector.toLowerCase() === 'pickup' || !deliveryEnabled
          ? MODAL_SELECT_STORE
          : MODAL_SELECT_STORE_DELIVERY;
      dispatch(setCurrentModal(shoppingMode));
    }
  }, [ageRestrictionAccepted, dispatch, storeSelector]);

  useEffect(() => {
    if (
      !deliveryEnabled &&
      window?.localStorage?.getItem('pickupOrDelivery') === 'delivery'
    ) {
      window?.localStorage?.setItem('pickupOrDelivery', 'pickup');
    }
  }, [deliveryEnabled]);

  useEffect(() => {
    setAgeGateEnabled(ageGateFF);
  }, [ageGateFF]);

  return (
    <div aria-hidden={modalOpen ? true : null}>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {ageGateEnabled && footer && footer.ageRestriction ? (
        <AgeGateModal {...footer.ageRestriction} />
      ) : null}
      {/*
       * There should be no way for header to not exist, and yet this is happening server-side
       * during the build process.
       *
       * Fixing this will come in a follow up.
       */}
      {header && <Header {...header} />}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <main className="page-container" onClick={interceptRichLinkClicks}>
        {children}
      </main>
      {footer && <Footer {...footer} />}
    </div>
  );
}
