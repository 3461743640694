import { useState, useEffect } from 'react';

const initialized = [];
export function useGoogleMapsApi(apiKey) {
  const [googleApi, setGoogleApi] = useState();

  useEffect(() => {
    if (window.google) {
      // if window.google object is already available just use it
      setGoogleApi(window.google);
      return;
    }
    const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;

    const existingScript = initialized.find((el) => el.src === src);

    const onLoad = () => {
      setGoogleApi(window.google);
    };
    if (existingScript) {
      // if script tag was added by other element just check when it is loaded
      existingScript.addEventListener('load', onLoad);
      return;
    }

    const script = document.createElement(`script`);
    script.src = src;
    script.async = true;
    script.defer = true;
    script.addEventListener(`load`, onLoad);
    document.body.append(script);
    initialized.push(script);

    return () => {
      script.removeEventListener(`load`, onLoad);
      script.remove();
      initialized.splice(
        initialized.findIndex((el) => el.src === src),
        1
      );
    };
  }, []);

  return googleApi;
}
