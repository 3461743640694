import { Provider } from 'react-redux';
import Script from 'next/script';
import SSRProvider from 'react-bootstrap/SSRProvider';
import DynamicDomainLayout from 'components/DynamicDomain/DynamicDomainLayout';
import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import { wrapper } from 'stores/store';
import 'styles/globalStyles.scss';
import { isJSON } from 'utils/is-json';

/* eslint-disable prefer-destructuring */
const NEXT_PUBLIC_GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

function MyApp({ Component, ...rest }) {
  const {
    store,
    props: {
      pageProps: { domain, footer, header, jsonld, metrics, ...componentProps },
    },
  } = wrapper.useWrappedStore(rest);

  return (
    <>
      <Provider store={store}>
        <SSRProvider>
          <ErrorBoundary>
            {domain && (
              <DynamicDomainLayout footer={footer} header={header}>
                <Component {...componentProps} />
              </DynamicDomainLayout>
            )}
            {!domain && (
              <Layout footer={footer} header={header}>
                <Component {...componentProps} />
              </Layout>
            )}
          </ErrorBoundary>
        </SSRProvider>
      </Provider>
      {/* eslint-disable react/no-danger */}
      {jsonld &&
        (isJSON(jsonld) ? (
          <Script
            dangerouslySetInnerHTML={{
              __html: jsonld,
            }}
            type="application/ld+json"
            id="jsonld"
          />
        ) : (
          <Script
            dangerouslySetInnerHTML={{
              __html:
                'console.error("The provided JSON-LD structure is invalid")',
            }}
            id="jsonld-error"
          />
        ))}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer=window.dataLayer||[];`,
        }}
        id="data-layer"
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer.push(${JSON.stringify(metrics ?? {})})`,
        }}
        id="initial-page-data"
      />
      {/* eslint-enable react/no-danger */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '${NEXT_PUBLIC_GTM_ID}');`,
        }}
        id="lume-gtm"
      />
      {footer?.zendeskChatUrl && (
        <Script
          id="ze-snippet"
          src={footer.zendeskChatUrl}
          strategy="lazyOnload"
          onLoad={() => {
            /* eslint-disable no-undef */
            if (zE) {
              zE('messenger:set', 'zIndex', 1050);
            }
            /* eslint-enable no-undef */
          }}
        />
      )}
      <noscript>
        {/* eslint-disable-next-line react/iframe-missing-sandbox */}
        <iframe
          title="GTM"
          src={`https://www.googletagmanager.com/ns.html?id=${NEXT_PUBLIC_GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  );
}

export default MyApp;
